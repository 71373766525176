<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Prises en charge
              </span>
            </h3>
            <div class="card-toolbar">
              <b-button
                v-if="csvLoading"
                variant="secondary"
                class="
                  font-weight-bolder font-size-sm
                  mr-3
                  d-flex
                  flex-row
                  align-items-center
                "
                disabled
              >
                <b-spinner small /> Télécharger en Excel
              </b-button>
              <b-button
                v-else
                @click.prevent="exportCsv"
                variant="secondary"
                class="
                  font-weight-bolder
                  mr-3
                  d-flex
                  flex-row
                  align-items-center
                "
              >
                <i class="fas fa-file-download mr-4" /> Télécharger en Excel
              </b-button>

              <a
                href="#"
                v-if="
                  hasUserRights([{ section: 'treatments', rights: ['create'] }])
                "
                @click.prevent="newItem"
                class="
                  btn btn-primary
                  font-weight-bolder
                  d-flex
                  flex-row
                  align-items-center
                "
              >
                <i class="fas fa-folder-plus mr-4" /> Nouvelle PEC
              </a>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-4">
            <!--begin::Table-->
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isTableLoading"
              :pagination-options="{ enabled: true }"
              :sort-options="{
                enabled: false,
                initialSortBy: serverParams.sort,
              }"
              :rows="rows"
              :columns="columns"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-row-click="toTreatment"
              styleClass="custom-table clickable"
            >
              <div slot="loadingContent" class="text-center">Chargement...</div>
              <div slot="emptystate" class="text-center">
                Aucun élément à afficher
              </div>

              <template #column-filter="{ column, updateFilters }">
                <template
                  v-if="
                    column.filterOptions && column.filterOptions.customFilter
                  "
                >
                  <v-select
                    @input="(e) => updateFilters(column, e)"
                    @search="
                      (search, loadingFn) =>
                        onSearchCustomFilter(column, search, loadingFn)
                    "
                    :filterable="false"
                    :options="customFilters[column.field]"
                    :reduce="(e) => e.id"
                    label="id"
                    placeholder="Tous"
                    required
                  >
                    <template v-slot:no-options>Rechercher...</template>
                    <template v-slot:option="option">
                      <div class="d-center">
                        {{ column.filterOptions.displayResults(option) }}
                      </div>
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="selected d-center">
                        {{ column.filterOptions.displayResults(option) }}
                      </div>
                    </template>
                  </v-select>
                </template>
              </template>

              <template slot-scope="props" slot="table-row">
                <!-- Column: Status -->
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusToColorClass(props.row.status)">
                    {{ statusToLabel(props.row.status) }}
                  </b-badge>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '25', '50', '100']"
                      class="mr-2 custom-select-sm"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap"> prises en charge par page </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="serverParams.page"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <i class="fas fa-chevron-left"></i>
                      </template>
                      <template #next-text>
                        <i class="fas fa-chevron-right"></i>
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->

    <b-modal
      v-model="modal.show"
      :title="modal.title"
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-2">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <i class="wizard-icon fas fa-user fa-2x"></i>
                <h3 class="wizard-title">1. Client</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fas fa-desktop fa-2x"></i>
                <h3 class="wizard-title">2. Appareil</h3>
              </div>
            </div>
            <!-- <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-responsive"></i>
                <h3 class="wizard-title">3. Select Services</h3>
              </div>
            </div> -->
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center mt-10">
          <div class="col-xl-12">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <VueRadioButton
                    v-model="modal.btnId"
                    :options="[
                      { title: 'Rechercher un client existant', id: 1, icon: 'fa fa-user-check' },
                      { title: 'Créer un nouveau client', id: 2, icon: 'fa fa-user-plus' },
                    ]"
                  >
                  <template #label="{ props }">
                    <i :class="props.icon" />
                    <span>{{ props.title }}</span>
                  </template>
                </VueRadioButton>

                <div class="mt-5" v-if="modal.btnId == 1">
                  <b-form-group>
                    <v-select
                      v-model="modal.item.customer"
                      @search="fetchCustomerOptions"
                      :options="customersOptions"
                      :filterable="false"
                      label="id"
                      placeholder="Rechercher un client (nom ou adresse email)..."
                      required
                    >
                      <template slot="no-options">Aucun client trouvé</template>
                      <template slot="option" slot-scope="option">
                        {{ option.firstname }} {{ option.lastname }} ({{
                          option.email
                        }})
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        {{ option.firstname }} {{ option.lastname }} ({{
                          option.email
                        }})
                      </template>
                    </v-select>
                  </b-form-group>
                </div>
                <div class="mt-5" v-else>
                  <b-row>
                    <b-col>
                      <b-form-group label="Prénom :">
                        <b-form-input
                          v-model="modal.customer.firstname"
                          type="text"
                          placeholder="Prénom"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Nom :">
                        <b-form-input
                          v-model="modal.customer.lastname"
                          type="text"
                          placeholder="Nom"
                          required
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Email :">
                        <b-form-input
                          v-model="modal.customer.email"
                          type="email"
                          placeholder="Email"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Téléphone :">
                        <b-form-input
                          v-model="modal.customer.phone"
                          type="tel"
                          placeholder="Téléphone"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group label="Adresse :">
                    <b-form-textarea
                      v-model="modal.customer.address"
                      placeholder="Adresse"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <details>
                  <summary class="mb-6">Affiner la recherche de modèle</summary>
                  <b-form-group label="Type d'appareil">
                    <v-select
                      v-model="modal.item.type"
                      @search="
                        (search, loading) =>
                          fetchDeviceOptions('types', search, loading)
                      "
                      @option:selected="(e) => deviceOptionSelected('type', e)"
                      :options="deviceTypesOptions"
                      label="name"
                      placeholder="Rechercher un type..."
                      required
                    >
                      <div slot="no-options">Aucun type trouvé</div>
                    </v-select>
                  </b-form-group>
                  <b-form-group label="Marque de l'appareil">
                    <v-select
                      v-model="modal.item.brand"
                      @search="
                        (search, loading) =>
                          fetchDeviceOptions('brands', search, loading)
                      "
                      @option:selected="(e) => deviceOptionSelected('brand', e)"
                      :options="deviceBrandsOptions"
                      label="name"
                      placeholder="Rechercher une marque..."
                      required
                    >
                      <div slot="no-options">Aucune marque trouvée</div>
                    </v-select>
                  </b-form-group>
                  <b-form-group label="Catégorie de l'appareil">
                    <v-select
                      v-model="modal.item.category"
                      @search="
                        (search, loading) =>
                          fetchDeviceOptions('categories', search, loading)
                      "
                      @option:selected="
                        (e) => deviceOptionSelected('category', e)
                      "
                      :options="deviceCategoriesOptions"
                      label="name"
                      placeholder="Rechercher une catégorie..."
                      required
                    >
                      <div slot="no-options">Aucune catégorie trouvée</div>
                    </v-select>
                  </b-form-group>
                </details>
                <b-form-group label="Modèle de l'appareil*">
                  <v-select
                    v-model="modal.item.deviceModel"
                    @search="
                      (search, loading) =>
                        fetchDeviceOptions('models', search, loading)
                    "
                    :options="deviceModelsOptions"
                    label="name"
                    placeholder="Rechercher un modèle..."
                    required
                  >
                    <div slot="no-options">Aucun modèle trouvé</div>
                  </v-select>
                </b-form-group>

                <!-- <div class="form-text">Informations appareil</div> -->
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Numéro de série</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="modal.item.deviceSerialNumber"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>IMEI</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="modal.item.deviceImei"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Mot de passe</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="modal.item.devicePassword"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Couleur</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="modal.item.deviceColor"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <b-button
                    class="btn"
                    variant="light-primary"
                    data-wizard-type="action-prev"
                  >
                    Précédent
                  </b-button>
                </div>
                <div>
                  <b-button
                    v-on:click="onSubmitItem"
                    class="btn"
                    variant="primary"
                    data-wizard-type="action-submit"
                  >
                    Créer la PEC
                  </b-button>
                  <b-button
                    class="btn"
                    variant="primary"
                    data-wizard-type="action-next"
                  >
                    Étape suivante
                  </b-button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import TreatmentStatus from "@/helpers/TreatmentStatus";
import VueRadioButton from "vue-radio-button";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import _ from "lodash";

export default {
  name: "treatments",
  components: { VueRadioButton },
  data() {
    return {
      columns: [
        {
          label: "Date",
          field: "createdAt",
          formatFn: this.dateToStr,
          sortable: true,
          width: "100px",
        },
        {
          label: "N°",
          field: "number",
          sortable: true,
          width: "90px",
          filterOptions: { enabled: true, fullMatch: true, placeholder: "N°" },
        },
        {
          label: "Client",
          field: "customer",
          formatFn: (e) => e ? `${e.firstname} ${e.lastname}` : 'Client supprimé',
          filterOptions: {
            enabled: true,
            customFilter: true,
            fullMatch: true,
            filterApi: {
              endpoint: "/customers",
              params: [
                "firstname_contains",
                "lastname_contains",
                "email_contains",
              ],
            },
            displayResults: (e) => `${e.firstname} ${e.lastname}`,
          },
        },
        {
          label: "Modèle",
          field: "deviceModel",
          formatFn: (e) => `${e.name}`,
          filterOptions: {
            enabled: true,
            customFilter: true,
            fullMatch: true,
            filterApi: {
              endpoint: "/device-models",
              params: ["name_contains"],
            },
            displayResults: (e) => e.name,
          },
        },
        {
          label: "N° Série",
          field: "deviceSerialNumber",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Rechercher..." },
        },
        {
          label: "IMEI",
          field: "deviceImei",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Rechercher..." },
        },
        {
          label: "Statut",
          field: "status",
          width: "10%",
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Tous",
            fullMatch: true,
            filter: true,
            filterDropdownItems: Object.keys(TreatmentStatus).map((e) => ({
              value: e,
              text: TreatmentStatus[e].label,
            })),
          },
        },

        // { label: "", field: "action", width: "80px" },
      ],
      rows: [],
      recents: [],
      customFilters: [],
      totalRecords: 0,
      isTableLoading: true,
      csvLoading: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "createdAt",
          type: "desc",
        },
        page: 1,
        perPage: 10,
      },
      modal: {
        show: false,
        title: "Nouvelle prise en charge",
        item: {
          id: null,
          number: null,
          doneAt: null,
          destocked: null,
          canceled: null,
          status: null,
          quotationDestocked: null,
          device: null,
          customer: null,
        },
        btnId: 1,
        customer: {
          firstname: null,
          lastname: null,
          email: null,
          phone: null,
          address: null,
        },
      },
      customersOptions: [],
      deviceTypesOptions: [],
      deviceBrandsOptions: [],
      deviceCategoriesOptions: [],
      deviceModelsOptions: [],
    };
  },
  computed: {
    ...mapGetters(["hasUserRights"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prises en charge (PEC)" }]);
  },
  methods: {
    toTreatment(props) {
      this.$router.push({ name: "treatment", params: { id: props.row.id } });
    },
    statusToColorClass(status) {
      return TreatmentStatus[status] ? TreatmentStatus[status].colorClass : "";
    },
    statusToLabel(status) {
      return TreatmentStatus[status] ? TreatmentStatus[status].label : "";
    },
    fetchCustomerOptions(search, loading) {
      if (!search.length) return;
      loading(true);
      this.fetchCustomerOptionsDebounce(search, loading, this);
    },
    fetchCustomerOptionsDebounce: _.debounce((search, loading, vm) => {
      vm.searchCustomerOptions(search, loading, false);
    }, 350),
    searchCustomerOptions(search, loading) {
      loading(true);
      ApiService.query("/customers", {
        params: {
          firstname_contains: search,
          lastname_contains: search,
          email_contains: search,
          _or: 1,
        },
      })
        .then(({ data }) => {
          this.customersOptions = data.results;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    fetchDeviceOptions(optionName, search, loading) {
      loading(true);
      this.searchDebounce(optionName, search, loading, this);
    },
    searchDebounce: _.debounce((optionName, search, loading, vm) => {
      vm.search(optionName, search, loading);
    }, 350),
    search(optionName, search, loading) {
      const searchOptions = { name_contains: search };

      // Refine models dependign on other criteria
      if (optionName == "models") {
        if (this.modal.item.type && this.modal.item.type.id) {
          searchOptions.type = this.modal.item.type.id;
        }
        if (this.modal.item.brand && this.modal.item.brand.id) {
          searchOptions.brand = this.modal.item.brand.id;
        }
        if (this.modal.item.category && this.modal.item.category.id) {
          searchOptions.category = this.modal.item.category.id;
        }
      }

      ApiService.query(`/device-${optionName}`, { params: searchOptions })
        .then(({ data }) => {
          if (optionName === "types") {
            this.deviceTypesOptions = data.results;
          } else if (optionName === "brands") {
            this.deviceBrandsOptions = data.results;
          } else if (optionName === "categories") {
            this.deviceCategoriesOptions = data.results;
          } else if (optionName === "models") {
            this.deviceModelsOptions = data.results;
          }
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    deviceOptionSelected(optionName, value) {
      console.log(optionName, value);
      if (["type", "brand", "category"].includes(optionName)) {
        // Refine models list
        this.search("models", "", () => {});
      }
    },
    hideModal() {
      this.modal.show = false;
    },
    newItem() {
      this.modal.item = {
        id: null,
        number: null,
        doneAt: null,
        destocked: null,
        canceled: null,
        status: null,
        quotationDestocked: null,
        device: null,
        customer: null,
      };
      this.modal.customer = {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        address: null,
      };
      this.modal.btnId = 1;
      this.modal.title = "Nouvelle prise en charge";

      // Pre-load options
      this.search("types", "", () => {});
      this.search("brands", "", () => {});
      this.search("categories", "", () => {});
      this.search("models", "", () => {});
      this.searchCustomerOptions("", () => {});

      this.modal.show = true;
    },
    onSubmitItem() {
      const treatment = _.cloneDeep(this.modal.item);

      treatment.deviceModel = this.modal.item.deviceModel.id;
      treatment.status = "PENDING_DIAGNOSIS";

      if (this.modal.item.customer && this.modal.item.customer.id) {
        // Existing (selected) customer
        treatment.customer = this.modal.item.customer.id;
        ApiService.post(`/treatments`, treatment).then(({ data }) => {
          this.$router.push({ name: "treatment", params: { id: data.id } });
          this.modal.show = false;
        });
      } else {
        // Create customer
        ApiService.post(`/customers`, this.modal.customer).then(
          ({ data: customer }) => {
            treatment.customer = customer.id;
            ApiService.post(`/treatments`, treatment).then(({ data }) => {
              this.$router.push({ name: "treatment", params: { id: data.id } });
              this.modal.show = false;
            });
          }
        );
      }
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Generate params for calls to the API
     */
    getApiParams() {
      const tableSearch = {};
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (
          this.columns.find((column) => column.field == e)?.filterOptions
            ?.fullMatch
        ) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      return {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: "customer deviceModel",
      };
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;
      ApiService.query("/treatments", {
        params: this.getApiParams(),
      })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    /**
     * Export table to CSV file
     */
    exportCsv() {
      this.csvLoading = true;
      ApiService.query("/treatments", {
        params: { ...this.getApiParams(), toCsv: 1 },
      })
        .then(({ data }) => {
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(data);
          anchor.target = "_blank";
          anchor.download = "prises-en-charge.csv";
          anchor.click();
          this.csvLoading = false;
        })
        .catch(() => {
          this.csvLoading = false;
        });
    },

    // CUSTOM FILTERS
    /**
     * Triggered when a search is performed on a custom filter in the table
     */
    onSearchCustomFilter(column, search, loading) {
      if (search.length) {
        loading(true);
        this.debounceCustomFilter(loading, column, search, this);
      }
    },
    /**
     * Perform custom filter via API (debounced)
     */
    debounceCustomFilter: _.debounce((loading, column, search, vm) => {
      const params = { _or: 1 };
      column.filterOptions.filterApi.params.forEach((e) => {
        params[e] = search;
      });

      ApiService.query(column.filterOptions.filterApi.endpoint, { params })
        .then((res) => {
          vm.customFilters[column.field] = res.data.results;
          loading(false);
        })
        .catch(() => {
          vm.customFilters[column.field] = [];
          loading(false);
        });
    }, 350),
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<style lang="scss" scoped>
  ::v-deep .v-radio-container {
    text-align: center;
  }

  ::v-deep .v-radio-label {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    padding: 10px;
    margin: 5px;
    border-radius: 4px;
    border: 1px solid #eee;
    transition: all 500ms;

    &:first-of-type {
      margin-right: 15px;
    }

    span {
      margin-top: 10px;
    }

    .fa {
      color: #3F4254;
    }
  }

  ::v-deep .v-radio-active {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: #3f0fd3;
    color: white;

    .fa {
      color: white;
    }
  }
</style>